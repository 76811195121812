import canUseDOM from 'dom-helpers/canUseDOM';

// !! Important: Values are not arbitrary.
// They match the ones used in UI packages, serverless packages, sdlc scripts, backend services etc.
export const AVAILABLE_ENV = {
  PROD: 'prod',
  QA: 'qa',
  DEV: 'alpha',
  LOCAL: 'local',
  TEST: 'test',
};

export const isBrowser =
  typeof window !== 'undefined' || typeof document !== 'undefined';

export const hostname = (() => {
  try {
    return new URL(
      process.env.REACT_APP_SIMON_ENV ||
        process.env.NEXT_PUBLIC_SIMON_ENV ||
        process.env.SIMON_ENV ||
        (isBrowser && window.location) ||
        ''
    ).hostname.replace(/www\./, '');
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return '';
})();

export const ENV = (() => {
  if (process.env.NODE_ENV === AVAILABLE_ENV.TEST) {
    return AVAILABLE_ENV.TEST;
  }
  // Serverless services
  if (process.env.ENV) {
    return process.env.ENV;
  }

  if (process.env.VITE_ENV) {
    return process.env.VITE_ENV;
  }

  // ICN Feature Staging
  if (hostname.includes('stg.icapitalnetwork.com')) {
    return AVAILABLE_ENV.DEV;
  }

  // Okteto
  if (hostname.includes('internal.icapitalnetwork.com')) {
    return AVAILABLE_ENV.DEV;
  }

  // ICN Staging
  if (hostname.includes('staging.icapitalnetwork.com')) {
    return AVAILABLE_ENV.QA;
  }

  // ICN PROD with WLP EX: pnc.icaptialnetwork.com
  if (hostname.includes('icapitalnetwork.com')) {
    return AVAILABLE_ENV.PROD;
  }

  switch (hostname) {
    case 'beta.simonmarkets.com':
    case 'prod.simonmarkets.com':
    case 'simonmarkets.com':
    case 'simon.io':
    case 'simon.figmarketing.com':
      return AVAILABLE_ENV.PROD;

    case 'qa.simonmarkets.com':
    case 'int.simonmarkets.com':
    case 'qa.simon.io':
    case 'qa.simon.figdevteam.com':
      return AVAILABLE_ENV.QA;

    case 'dev.simonmarkets.com':
    case 'alpha.simonmarkets.com':
    case 'alpha.scratch.internal.simon.io':
    case 'dmz.alpha.scratch.internal.simon.io':
    case 'alpha.simon.io':
      return AVAILABLE_ENV.DEV;
  }

  console.warn(
    `Cannot determine the current environment using host ${hostname}. Using ${AVAILABLE_ENV.LOCAL}`
  );
  return AVAILABLE_ENV.LOCAL;
})();

const urlFromEnv = {
  [AVAILABLE_ENV.LOCAL]: 'http://localhost',
  [AVAILABLE_ENV.DEV]: 'https://alpha.simon.io',
  [AVAILABLE_ENV.QA]: 'https://qa.simon.io',
  [AVAILABLE_ENV.PROD]: 'https://simon.io',
};

export const ioMetadataBase = urlFromEnv[ENV];

export const getIoBase = (service, env = ENV || AVAILABLE_ENV.LOCAL) => {
  const isLocal = env === AVAILABLE_ENV.LOCAL;

  const base = urlFromEnv[env];

  const maybePort = isLocal
    ? {
        'simon-io-user': 3001,
        'content-service': 3003,
      }[service]
    : null;

  let path = {
    'simon-io-user': 'api/user',
    'content-service': 'api',
  }[service];

  if (isLocal) {
    // For the moment, local paths are nestled within the 'sandbox' stage
    path = `sandbox/${path}`;
  }

  const url = new URL(path, base);
  if (maybePort) {
    url.port = maybePort;
  }

  return url.toString();
};

export const isBrowserIE = isBrowser && window.document.documentMode;

export function getConfig() {
  let release;
  try {
    // Ex.                                         <namespaceOrApp>                  <release>
    // https://www.dev.simonmarkets.com/apps/unified-education/nk-appserv-67586-latest-symlink/assets/index-e57b0eb3.js
    const match = new URL(
      document.currentScript?.src || import.meta.url
    ).pathname.match(
      // eslint-disable-next-line security/detect-unsafe-regex
      /\/apps\/(?<namespaceOrApp>[^/]+)\/(?<appOrRelease>[^/]+)\/(?<maybeRelease>[^/]+)\//
    );
    const namespaceOrApp = match?.groups?.namespaceOrApp || '';
    if (namespaceOrApp.startsWith('@')) {
      release = match?.groups?.maybeRelease;
    } else {
      release = match?.groups?.appOrRelease;
    }
  } catch (e) {
    // Fallback below
  }
  if (!release) {
    // Could be a cached value (NX) from a previous build
    release = process.env.REACT_APP_RELEASE;
  }

  const isSentryEnabled = Boolean(
    process.env.REACT_APP_USE_SENTRY === 'true' ||
      process.env.NEXT_PUBLIC_USE_SENTRY === 'true' ||
      ((ENV === AVAILABLE_ENV.PROD || ENV === AVAILABLE_ENV.QA) &&
        process.env.NODE_ENV === 'production')
  );

  const debugMixPanel = process.env.REACT_APP_DEBUG_MIXPANEL === 'true';

  const isRealtimeEnabled = Boolean(
    ENV !== AVAILABLE_ENV.DEV &&
      (process.env.REACT_APP_ENABLE_REALTIME === 'true' ||
        process.env.NODE_ENV === 'production')
  );

  return {
    manifest: isBrowser ? window.manifest : {},
    release,
    isRealtimeEnabled,
    isSentryEnabled,
    debugMixPanel,
  };
}

export const CONFIG = getConfig();

// Squid Proxy Url needed for Fetch APIs that need access to internet
export const proxyUrl =
  process.env.CI === 'true'
    ? // don't use the proxy during CI builds
      null
    : ENV === AVAILABLE_ENV.PROD
    ? 'http://@internal-prod-squid-elb-744301762.us-east-1.elb.amazonaws.com:3128'
    : ENV === AVAILABLE_ENV.QA
    ? 'http://@internal-qa-squid-elb-1135562419.us-east-1.elb.amazonaws.com:3128'
    : null;

// Detects if SIMON is running inside an IFrame
export const isIFrameFn = () => canUseDOM && window.self !== window.top;
export const isIFrame = isIFrameFn();

export const referrerURL = (() => {
  if (!isBrowser) return;
  try {
    return new URL(document.referrer);
  } catch (e) {
    // empty
  }
})();

export const getEnvColor = () => {
  switch (ENV) {
    case AVAILABLE_ENV.PROD:
      return '#6ABF83';
    case AVAILABLE_ENV.QA:
      return '#5C8FDC';
    case AVAILABLE_ENV.DEV:
      return '#5958B4';
    default:
      return '#1F314A';
  }
};

export const ICN_ROOT_DOMAIN = 'icapitalnetwork.com';

export const isUnifiedPlatform =
  isBrowser && window.location.hostname.includes(ICN_ROOT_DOMAIN);
