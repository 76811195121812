import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, Navigate } from 'react-router-dom';
import useAuth from './useAuth';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Link as SupernovaLink,
  TextField,
  InputAdornment,
} from '@icapitalnetwork/supernova-core';
import { useQueryParam } from '@simon/core/hooks/useQueryParam';
import Divider from '@icapitalnetwork/supernova-core/Divider';
import PageTitle from './common/PageTitle';
import { Col, Row } from '@simon/ui/Grid';
import ErrorMessage from './common/ErrorMessage';
import {
  EVENT,
  LOGIN_MODE,
  SELECT_OPTION_TYPES,
  AUTH_LOGOUT_REASON_KEY,
  AUTH_LOGOUT_REASONS,
} from './constants';
import SelectOption from './common/SelectOption';
import styles from './LoginPage.module.scss';
import { icnApi } from './utils';
import Alert from '@simon/ui/Alert';
import { InvertedInfo, Success } from '@simon/ui/Icon';

const findUserByNetworkName = (users, networkName) =>
  users?.find(user => user.networkName === networkName);

export default function LoginPage({ showDevLogin, initialMasterUser }) {
  const [$email, , remove$email] = useQueryParam('email');
  const [$networkName, , remove$networkName] = useQueryParam('networkName');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isTouched, setIsTouched] = useState(false);
  const [magicUsers, setMagicUsers] = useState<{ id: string; email: string }[]>(
    [
      {
        id: '92904',
        email: 'yyoo+pafadmin@icapitalnetwork.com',
      },
    ]
  );

  const [showInactivityLogoutAlert, setShowInactivityLogoutAlert] =
    useState(false);

  useEffect(
    () =>
      setShowInactivityLogoutAlert(
        sessionStorage.getItem(AUTH_LOGOUT_REASON_KEY) ===
          AUTH_LOGOUT_REASONS.INACTIVITY
      ),
    []
  );

  useEffect(() => {
    if (!showDevLogin) return;

    (async () => {
      try {
        const { users } = await icnApi.get('/v1/menu/dev_users.json');
        setMagicUsers(users);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [showDevLogin]);

  const auth = useAuth();
  const {
    transaction,
    setTransaction,
    isAuthenticated,
    setIsAuthenticated,
    getRememberedMasterUser,
    saveRememberedMasterUser,
    removeRememberedMasterUser,
  } = auth;

  // todo: Support async getRememberedMasterUser
  const persistedMasterUser = useMemo(
    () => getRememberedMasterUser(),
    [getRememberedMasterUser]
  );

  // (Authenticated users) Consumer app should provide the currently logged-in master-user via initialMasterUser
  // (Unauthenticated users) Infer from last "Remember me" master user
  const [masterUser, setMasterUser] = useState(() => {
    const masterUser = initialMasterUser || persistedMasterUser;
    if ($email) {
      // if an email is passed as a query param, verify the persisted masterUser
      if ($email === masterUser?.email) {
        return masterUser;
      }
      // Persisted email is different. Clear persisted value and mark as null so that it refetches.
      removeRememberedMasterUser();
      return null;
    }
    return masterUser;
  });
  const [selectedUser, setSelectedUser] = useState(() => {
    // already logged in, switching network
    if (masterUser && $networkName) {
      return findUserByNetworkName(masterUser.users, $networkName);
    }
    return null;
  });
  const [email, setEmail] = useState($email || masterUser?.email || '');
  const [rememberMe, setRememberMe] = useState(!!persistedMasterUser);
  const _passwordInput = useRef(null);
  const [password, setPassword] = useState('');

  // "post login" hook
  // - handle "remember me" feature
  const onLoginSuccess = useCallback(
    async (_masterUser = masterUser) => {
      try {
        if (rememberMe) {
          await saveRememberedMasterUser(_masterUser);
        } else {
          removeRememberedMasterUser();
        }
      } catch (e) {
        console.error('onAfterLogin failed', e);
      }
    },
    [
      masterUser,
      rememberMe,
      removeRememberedMasterUser,
      saveRememberedMasterUser,
    ]
  );

  // Wrapper of auth.loginCredentials() with error reporting and handling of "Remember me"
  const loginCredentials = useCallback(
    async (username, password, _masterUser = masterUser) => {
      setIsLoading(true);
      try {
        const masterUserDoc = _masterUser?.users?.find(
          d => d.login === username
        );
        const transaction = await auth.loginCredentials({
          username,
          password,
          loginMode: masterUserDoc?.loginMode,
          experience: masterUserDoc?.experience,
        });
        if (transaction.status === 'SUCCESS') {
          await onLoginSuccess(_masterUser);
        }
        setTransaction(transaction);
      } catch (e) {
        setError(
          'The email and password you entered did not match our records.'
        );
      } finally {
        setIsLoading(false);
      }
    },
    [auth, masterUser, onLoginSuccess, setTransaction]
  );

  // Wrapper of auth.loginSSO() with
  // - error reporting
  // - handling of "Remember me"
  // - support for both ssoPrefix and embeddingInfo
  const loginSSO = useCallback(
    async user => {
      setIsLoading(true);
      try {
        await auth.loginSSO(user);
        await onLoginSuccess();
      } catch (e) {
        auth.onEvent(EVENT.LoginError, { loginMode: user.loginMode, error: e });
        // will show the password field
      } finally {
        setIsLoading(false);
      }
    },
    [auth, onLoginSuccess]
  );

  const fetchMasterUser = useCallback(
    async email => {
      setIsLoading(true);
      try {
        const res = await auth.getMasterUser(email);
        auth.onEvent(EVENT.MasterUserFetchSuccess, res);

        // Automatic handling when user is assigned only 1 network
        if (res.users.length === 1) {
          const [user] = res.users;

          // Redirect immediately SSO/Embedded users
          if ([LOGIN_MODE.SSO, LOGIN_MODE.Embedded].includes(user.loginMode))
            return await loginSSO(user);

          // Autocompleted password and only one user available -> try to login.
          if (password) {
            await loginCredentials(user.login, password, res);
          }
        }

        setMasterUser(res);
        // Switching network. For authenticated users, selectedUser can't be found until full master user is fetch.
        if ($networkName) {
          setSelectedUser(findUserByNetworkName(res.users, $networkName));
          // @ts-ignore
          remove$networkName?.();
        }
        setIsLoading(false);
        setTimeout(() => _passwordInput.current?.focus(), 0);
        return res;
      } catch (e) {
        auth.onEvent(EVENT.MasterUserFetchError);
        setMasterUser({});
        setIsLoading(false);
      }
    },
    [
      $networkName,
      remove$networkName,
      auth,
      loginCredentials,
      loginSSO,
      password,
    ]
  );

  const onSubmit = useCallback(
    async e => {
      e?.preventDefault();
      if (isLoading) return;

      // password was entered -> auth using Okta
      if (password && masterUser) {
        return loginCredentials(masterUser.users?.[0].login || email, password);
      }

      // Fetch master user info
      return fetchMasterUser(email);
    },
    [email, fetchMasterUser, isLoading, loginCredentials, masterUser, password]
  );

  // Fetch masterUser for email passed as a query param
  useEffect(() => {
    if ($email) {
      fetchMasterUser($email).then(() =>
        // @ts-ignore
        remove$email()
      );
    }
    // Important: This should execute only once for the initial `emailQuery`
    // after that the email is controlled from the UI
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refetch masterUser for persisted email
  useEffect(() => {
    if (masterUser && !$email) {
      fetchMasterUser(masterUser.email);
    }
    // Important: This should execute only once for the initial persisted email.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reroute specific transactions to their appropriate page.
  // Logic should be mostly the same as handling of resumed transactions in AuthPage.js
  // Verify if you need to have your case in both files.
  if (transaction) {
    if (transaction.status === 'PASSWORD_EXPIRED') {
      return (
        <Navigate replace to="expired" state={{ oldPassword: password }} />
      );
    } else if (transaction.status.includes('MFA_')) {
      return <Navigate replace to="mfa" state={{ email }} />;
    }
  }

  const ForgotPassword = login => (
    // @ts-ignore
    <SupernovaLink component={Link} to="reset" state={{ login, masterUser }}>
      Forgot password?
    </SupernovaLink>
  );

  const PasswordInput = (
    // @ts-ignore
    <TextField
      fullWidth
      hideClearButton
      inputRef={_passwordInput}
      sx={{ mt: 0.5, display: !masterUser && 'none' }}
      type="password"
      data-lpignore={$email ? 'true' : 'false'}
      autoComplete={$email ? 'off' : 'current-password'}
      placeholder="Password"
      name="password"
      value={password}
      onChange={e => setPassword(e.target.value)}
      required={!!masterUser}
      aria-label="Password"
      InputProps={{
        startAdornment: (
          // @ts-ignore
          <InputAdornment position="start">
            <LockIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );

  const GoBack = (
    <div className={styles.goBackContainer}>
      <p>Don&apos;t see your network?</p>
      {/* @ts-ignore*/}
      <Button
        variant="text"
        size="md"
        onClick={() => {
          setEmail('');
          setPassword('');
          setMasterUser(null);
          setSelectedUser(null);
          setError(null);
        }}
      >
        Try a different email
      </Button>
    </div>
  );

  if (masterUser?.users?.length > 1) {
    // Hybrid network -> Can choose between SSO or password-based auth
    if (
      [
        LOGIN_MODE.SSOAndUsernamePassword,
        LOGIN_MODE.EmbeddedAndUsernamePassword,
      ].includes(selectedUser?.loginMode)
    ) {
      return (
        <React.Fragment>
          <PageTitle
            title={
              <React.Fragment>
                Please sign in via Single Sign-On or
                <br />
                with your email and password.
              </React.Fragment>
            }
            onGoBack={() => {
              // go back to list of networks
              setError(null);
              setPassword('');
              setSelectedUser(null);
            }}
          />
          <form
            onSubmit={e => {
              e.preventDefault();
              return loginCredentials(selectedUser.login, password);
            }}
          >
            <SelectOption
              title={
                selectedUser.ssoPrefix?.ssoSystemName
                  ? `Sign in via ${selectedUser.ssoPrefix.ssoSystemName}`
                  : selectedUser.embeddingInfo?.hostApplicationName
                  ? `Sign in via ${selectedUser.embeddingInfo.hostApplicationName}`
                  : `Sign in via ${selectedUser.networkName} with SSO`
              }
              centered
              type={SELECT_OPTION_TYPES.regular}
              onClick={() => loginSSO(selectedUser)}
            />
            {/* @ts-ignore */}
            <Divider sx={{ m: '2rem 0' }}>or</Divider>
            <input
              type="hidden"
              name="username"
              value={masterUser.email}
              tabIndex={-1}
            />
            <SelectOption
              title={selectedUser.networkName}
              subtitle={email}
              type={SELECT_OPTION_TYPES.regular}
            />
            {PasswordInput}
            {/* @ts-ignore*/}
            <Row
              justify="end"
              align="middle"
              className={styles.forgotPasswordRow}
            >
              {/* @ts-ignore*/}
              <Col>{ForgotPassword(selectedUser.login)}</Col>
            </Row>
            {error && <ErrorMessage error={error} />}
            {/* @ts-ignore*/}
            <Button
              fullWidth
              size="md"
              type="submit"
              disabled={isLoading || !password}
              sx={{ mt: 1 }}
              startIcon={
                isLoading && (
                  <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
                )
              }
            >
              Sign In
            </Button>
          </form>
        </React.Fragment>
      );
    }
    // Non-SSO network -> Show password field
    if (selectedUser?.loginMode === LOGIN_MODE.UsernamePassword) {
      return (
        <React.Fragment>
          <PageTitle
            small
            title="Please enter your password."
            onGoBack={() => {
              // go back to list of networks
              setError(null);
              setPassword('');
              setSelectedUser(null);
            }}
          />
          <form
            onSubmit={e => {
              e.preventDefault();
              return loginCredentials(selectedUser.login, password);
            }}
          >
            <SelectOption
              title={selectedUser.networkName}
              subtitle={email}
              type={SELECT_OPTION_TYPES.regular}
            />
            <input
              type="hidden"
              name="username"
              value={masterUser.email}
              tabIndex={-1}
            />
            {PasswordInput}
            {/* @ts-ignore */}
            <Row
              justify="end"
              align="middle"
              className={styles.forgotPasswordRow}
            >
              {/* @ts-ignore */}
              <Col>{ForgotPassword(selectedUser.login)}</Col>
            </Row>
            {error && <ErrorMessage error={error} />}
            {/* @ts-ignore */}
            <Button
              fullWidth
              size="md"
              type="submit"
              disabled={isLoading || !password}
              sx={{ mt: 0.5 }}
              startIcon={
                isLoading && (
                  <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
                )
              }
            >
              Sign In
            </Button>
          </form>
          {GoBack}
        </React.Fragment>
      );
    }

    // List of networks
    return (
      <React.Fragment>
        <PageTitle
          small
          title={
            // All networks SSO or embedded
            masterUser.users.every(user =>
              [LOGIN_MODE.SSO, LOGIN_MODE.Embedded].includes(user.loginMode)
            )
              ? 'Please sign in via Single Sign-On.'
              : 'Please select a network to launch.'
          }
          subtitle={masterUser.email}
        />
        {masterUser.users.map(user => (
          <SelectOption
            key={user.networkName}
            title={user.networkName}
            type={SELECT_OPTION_TYPES.regular}
            centered
            onClick={() => {
              if (
                [LOGIN_MODE.SSO, LOGIN_MODE.Embedded].includes(user.loginMode)
              ) {
                return loginSSO(user);
              }
              setSelectedUser(user);
            }}
          />
        ))}
        {GoBack}
      </React.Fragment>
    );
  }

  // Disable native autocomplete if: Email is provided from URL or if "Remember-me" data was restored and form is not touched yet.
  // This is to prevent the native feature from overriding the remember-me value.
  // On touch, autocomplete is re-enabled.
  const isAutocompleteDisabled = ($email || masterUser) && !isTouched;

  // Logged in ICN user
  if (typeof isAuthenticated === 'object') {
    return (
      <React.Fragment>
        <PageTitle
          title={
            <React.Fragment>
              {isAuthenticated.name}
              <br />({isAuthenticated.company.name})
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PageTitle title="Welcome!" />
      {showInactivityLogoutAlert && (
        <Alert
          textContainerClassName=""
          showClose
          showIcon
          className={styles.inactivityAlert}
          description=""
          message="You have successfully logged out."
          type="success"
          icon={<Success width={14} height={14} />}
          onClose={() => sessionStorage.removeItem(AUTH_LOGOUT_REASON_KEY)}
        />
      )}
      {masterUser?.users?.[0].loginMode === LOGIN_MODE.UnifiedPassword && (
        <Alert
          showIcon
          className={styles.unifiedPasswordAlert}
          description={
            <div className={styles.unifiedPasswordAlertDescription}>
              <span>
                iCapital&apos;s Structured Investments and Alternatives
                platforms are merging in early 2025. As a part of this process,
                please use your Alternatives platform credentials to log in from
                now on.
              </span>
              <span>
                If you don&apos;t know your Alternatives platform credentials,
                please click &apos;Forgot password?&apos; below to reset your
                password for both platforms.
              </span>
            </div>
          }
          message="Use iCapital credentials to log in"
          type="info"
          icon={<InvertedInfo />}
        />
      )}
      <form onSubmit={onSubmit}>
        {/* @ts-ignore*/}
        <TextField
          fullWidth
          hideClearButton
          autoFocus
          type="email"
          data-lpignore={isAutocompleteDisabled ? 'true' : 'false'}
          autoComplete={isAutocompleteDisabled ? 'off' : 'username'}
          placeholder="Firm Email"
          name="username"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
            setError(null);
            setPassword('');
            setMasterUser(null);
          }}
          onFocus={() => {
            setIsTouched(true);
          }}
          required
          aria-label="Email"
          InputProps={{
            startAdornment: (
              // @ts-ignore
              <InputAdornment position="start">
                <PersonIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        {PasswordInput}
        {/* @ts-ignore */}
        <Row
          justify="space-between"
          align="middle"
          className={styles.forgotPasswordRow}
        >
          {/* @ts-ignore */}
          <Col>
            {/* @ts-ignore */}
            <FormControlLabel
              sx={{ ml: '-11px' }}
              control={
                // @ts-ignore
                <Checkbox
                  checked={rememberMe}
                  onChange={e => setRememberMe(e.target.checked)}
                />
              }
              label="Remember me"
            />
          </Col>
          {/* @ts-ignore */}
          <Col>{masterUser && ForgotPassword(email)}</Col>
        </Row>
        {error && <ErrorMessage error={error} />}
        {/* @ts-ignore */}
        <Button
          fullWidth
          size="md"
          sx={{ mt: 1 }}
          type="submit"
          disabled={isLoading}
          startIcon={
            isLoading && (
              <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
            )
          }
        >
          {!masterUser ? 'Next' : 'Sign In'}
        </Button>
        {showDevLogin && (
          <select
            className={styles.magicLogin}
            onChange={async e => {
              setError(null);
              try {
                // {"success":true,"info":"Logged In","user":{"id":92904},"phone":"2129947351","login_landing_page":"/subscriptions_dashboard","csrf_token":"UkDrru6VSMip8GsZE7JEAPsdz+hLABrFviAN5b5/8cm6H/ETnoz3bPygodVo3L06iNuAuPY79vYegCCmKKVwIw==","mfa_registration_required":false}
                const { success, login_landing_page } = await icnApi.post(
                  '/v1/login.json',
                  {
                    dev_user: {
                      user_id: e.currentTarget.value,
                      remember_me: '',
                    },
                  }
                );
                window.location.href = login_landing_page;
                setIsAuthenticated(success);
              } catch (e) {
                setError(
                  `Couldn't login with magic link: ${JSON.stringify(e)}`
                );
              }
            }}
          >
            <option value="">Magic Login</option>
            {magicUsers.map(({ id, email }) => (
              <option key={id} value={id}>
                {email}
              </option>
            ))}
          </select>
        )}
      </form>
    </React.Fragment>
  );
}
