import { useState, useCallback } from 'react';
import { AVAILABLE_ENV, ENV } from '@simon/config/env';
import storageAvailable from './storageAvailable';
import { authService } from '@simon/auth';
import {
  gtmPushOldSimon,
  gtmInitialize,
  gtmPush,
  gtmSetOldSimon,
} from '@simon/core/services/gtm';
import { isEmpty } from 'lodash';
import { captureException, SCOPES } from '../sentry';

// /////////////
//  Constants //
// ////////////

export const SI = 'SIs';
export const ETFS = 'ETFs';
export const SMAS = 'SMAs';
export const SPECTRUM = 'Spectrum';
export const RAINBOW = 'Rainbow';
export const ANNUITIES = 'Annuities';
export const ALTERNATIVES = 'Alternatives';
export const HEATMAP = 'Builder Heatmap';
export const RFQ = 'RFQ';
export const BUILDER = 'Builder';
export const BUILDERV2 = 'Builder 2.0';
export const HOME_PAGE = 'Home Page';
export const WELCOME_MODAL = 'Welcome Modal';
export const LIFECYCLE_PORTAL = 'Lifecycle Portal';
export const CLIENT_PAGE = 'Client Page';
export const SALESBOOK = 'Salesbook';
export const BACKTESTING = 'Backtesting';
export const CONTRACT_HIGHLIGHTS = 'Contract Highlights';
export const CONTRACT_EDITOR = 'Contract Editor';
export const PLATFORM = 'Platform';
export const BEACON = 'Beacon';
export const DASHBOARD_MY_PRODUCT = 'Dashboard My Product';
export const DASHBOARD_LIFECYCLE = 'Dashboard Lifecycle';
export const SPONSOR_PORTAL = 'Sponsor Portal';
export const MARKETPLACE = 'Marketplace';
export const ISSUER_PORTAL = 'Issuer Portal';
export const ISSUER_DASHBOARD = 'Issuer Dashboard';
export const ALLOCATION_BACKTESTING = 'Allocation Backtesting';
export const EFFICIENT_FRONTIER = 'Efficient Frontier';
export const INVESTMENT_DETAILS = 'Investment Details';
export const COMPARISON_CALCULATOR = 'Comparison Calculator';
export const DASHBOARD_MY_EDUCATION = 'Dashboard My Education';
export const NAVIGATION = 'Navigation';
export const PAYOUT_CHART = 'Payout Chart';
export const DASHBOARD = 'Dashboard';
export const LEARNING_CENTER = 'Learning Center';
export const PORTFOLIO_OPTIMIZER = 'Portfolio Optimizer';
export const INVESTMENT_ACTIVITY = 'Investment Activity';
export const PERF_ANALYSIS = 'Perf Analysis';
export const DASHBOARD_NETWORK_TRAINING_ANN =
  'Dashboard My Network Product Training: Annuities';
export const PAST_OFFERINGS = 'Past Offerings';
export const QUICK_SEARCH = 'Search';
export const CURRENT_OFFERINGS = 'Current Offerings';
export const MANAGE_NETWORK = 'Manage Network';
export const INVESTOR_TOOLS = 'Investor Tools';
export const INVESTOR_FRIENDLY_RESOURCES = 'Investor-Friendly Resources';
export const MANAGED_NOTE_STRATEGIES = 'Managed Note Strategies';
export const ACCOUNT_ACTIVITY_PORTAL = 'Account Activity Portal';
export const ACCOUNT_PORTAL = 'Account Portal';
export const STRESS_TESTING_ANALYSIS = 'Stress Testing Analysis';
export const RFQ_PORTAL = 'RFQ Portal';
export const MANAGE_EMAIL_NOTIFICATIONS = 'Manage Email Notifications';
export const IOI = 'IOIs';
export const INDEX_STRATEGY_BACKTESTING = 'Index Strategy Backtesting';
export const INVESTMENT_DETAILS_MODAL = 'Investment Details Modal';
export const ANNUITY_COMPARISON = 'Annuity Comparison';
export const ONBOARDING_FLOW = 'Onboarding Flow';
export const RJ_TYPES = 'RJ Types';
export const HISTORICAL_HOLDINGS = 'Historical Holdings';
export const ARCHITECT = 'Architect';
export const PROFILE = 'Profile';

//  Tags
export const LEARNING_CENTER_TAG = `[${LEARNING_CENTER}]`;
export const SI_TAG = `[${SI}]`;
export const ANNUITIES_TAG = `[${ANNUITIES}]`;
export const SPECTRUM_TAG = `[${SPECTRUM}]`;
export const ALTERNATIVES_TAG = `[${ALTERNATIVES}]`;
export const HEATMAP_TAG = `[${HEATMAP}]`;
export const SMAS_TAG = `[${SMAS}]`;
export const RFQ_TAG = `[${RFQ}]`;
export const ETFS_TAG = `[${ETFS}]`;
export const BUILDER_TAG = `[${BUILDER}]`;
export const HOME_PAGE_TAG = `[${HOME_PAGE}]`;
export const WELCOME_MODAL_TAG = `[${WELCOME_MODAL}]`;
export const LIFECYCLE_PORTAL_TAG = `[${LIFECYCLE_PORTAL}]`;
export const CLIENT_PAGE_TAG = `[${LIFECYCLE_PORTAL} ${CLIENT_PAGE}]`;
export const SALESBOOK_TAG = `[${SALESBOOK}]`;
export const MARKETPLACE_TAG = `[${MARKETPLACE}]`;
export const PAST_OFFERINGS_TAG = `[${PAST_OFFERINGS}]`;
export const CURRENT_OFFERINGS_TAG = `[${CURRENT_OFFERINGS}]`;
export const BACKTESTING_TAG = `[${BACKTESTING}]`;
export const INVESTMENT_DETAILS_TAG = `[${INVESTMENT_DETAILS}]`;
export const ISSUER_PORTAL_TAG = `[${ISSUER_PORTAL}]`;
export const CONTRACT_HIGHLIGHTS_TAG = `[${CONTRACT_HIGHLIGHTS}]`;
export const EFFICIENT_FRONTIER_TAG = `[${EFFICIENT_FRONTIER}]`;
export const ALLOCATION_BACKTESTING_TAG = `[${ALLOCATION_BACKTESTING}]`;
export const PLATFORM_TAG = `[${PLATFORM}]`;
export const BEACON_TAG = `[${BEACON}]`;
export const DASHBOARD_MY_PRODUCT_TAG = `[${DASHBOARD_MY_PRODUCT}]`;
export const DASHBOARD_LIFECYCLE_TAG = `[${DASHBOARD_LIFECYCLE}]`;
export const DASHBOARD_MY_EDUCATION_TAG = `[${DASHBOARD_MY_EDUCATION}]`;
export const DASHBOARD_NETWORK_TRAINING_ANN_TAG = `[${DASHBOARD_NETWORK_TRAINING_ANN}]`;
export const DASHBOARD_TAG = `[${DASHBOARD}]`;
export const SPONSOR_PORTAL_TAG = `[${SPONSOR_PORTAL}]`;
export const COMPARISON_CALCULATOR_TAG = `[${COMPARISON_CALCULATOR}]`;
export const PAYOUT_CHART_TAG = `[${PAYOUT_CHART}]`;
export const PORTFOLIO_OPTIMIZER_TAG = `[${PORTFOLIO_OPTIMIZER}]`;
export const INVESTMENT_ACTIVITY_TAG = `[${INVESTMENT_ACTIVITY}]`;
export const PERF_ANALYSIS_TAG = `[${PERF_ANALYSIS}]`;
export const QUICK_SEARCH_TAG = `[${QUICK_SEARCH}]`;
export const MANAGE_NETWORK_TAG = `[${MANAGE_NETWORK}]`;
export const INVESTOR_TOOLS_TAG = `[${INVESTOR_TOOLS}]`;
export const ACCOUNT_ACTIVITY_PORTAL_TAG = `[${ACCOUNT_ACTIVITY_PORTAL}]`;
export const ACCOUNT_PORTAL_TAG = `[${ACCOUNT_PORTAL}]`;
export const STRESS_TESTING_ANALYSIS_TAG = `[${STRESS_TESTING_ANALYSIS}]`;
export const MANAGE_EMAIL_NOTIFICATIONS_TAG = `[${MANAGE_EMAIL_NOTIFICATIONS}]`;
export const IOI_TAG = `[${IOI}]`;
export const INDEX_STRATEGY_BACKTESTING_TAG = `[${INDEX_STRATEGY_BACKTESTING}]`;
export const INVESTMENT_DETAILS_MODAL_TAG = `[${INVESTMENT_DETAILS_MODAL}]`;
export const ANNUITY_COMPARISON_TAG = `[${ANNUITY_COMPARISON}]`;
export const ONBOARDING_FLOW_TAG = `[${ONBOARDING_FLOW}]`;
export const RJ_TYPES_TAG = `[${RJ_TYPES}]`;
export const HISTORICAL_HOLDINGS_TAG = `[${HISTORICAL_HOLDINGS}]`;
export const ARCHITECT_TAG = `[${ARCHITECT}]`;
export const PROFILE_TAG = `[${PROFILE}]`;

//  Event Properties/Actions
export const OVERVIEW = 'Overview';
export const HOLDINGS = 'Holdings';
export const ASSET_CLASS = 'Asset Class';
export const SHARE = 'Share';
export const VIEW = 'View';
export const CONTRACTS = 'Contracts';
export const ALL = 'All';
export const LOAD = 'Load';
export const CLICK = 'Click';
export const SELECT = 'Select';
export const ENTER = 'Press Enter';
export const CLEAR_INPUT = 'Clear Input';
export const HOVER = 'Hover';
export const APPROVE_POLICY = 'Approve Policy';
export const UNAPPROVE_POLICY = 'Unapprove Policy';
export const APPLY = 'Apply';
export const APPLY_FILTER = 'Apply Filter';
export const APPLY_SORTING = 'Apply Sorting';
export const DROP = 'Drop';
export const PASTE = 'Paste';
export const ERROR = 'Error';
export const DOWNLOAD = 'Download';
export const FLOW_NAME = 'flowName';
export const FLOW_TIMESTAMP = 'flowTimeStamp';
export const DISPLAY_NAME = 'Display Name';
export const DESCRIPTION = 'Description';
export const UPLOAD = 'Upload';

const IMPERSONATED_USER_ID = 'impersonated_user_id';
//  Utils
export const isSessionStorageAvailable = storageAvailable('sessionStorage');

export const startFlow = flowName => {
  if (isSessionStorageAvailable) {
    sessionStorage.setItem(FLOW_NAME, flowName);
    sessionStorage.setItem(FLOW_TIMESTAMP, new Date().getTime());
  }
};

let userIdentified = false;

export const trackEvent = async (eventName, properties = {}) => {
  if (!userIdentified) {
    console.warn(
      `Ignored tracking event ${eventName} because user was not set.`,
      properties
    );
    return;
  }

  if (typeof properties === 'object') {
    properties.event_timestamp = new Date().getTime();
  }
  if (isSessionStorageAvailable) {
    properties[FLOW_NAME] = sessionStorage.getItem(FLOW_NAME);
    const flowTime = Number(sessionStorage.getItem(FLOW_TIMESTAMP));
    properties[FLOW_TIMESTAMP] = !isNaN(flowTime) ? flowTime : null;
    const claims = await authService.getClaims();
    if (claims?.impersonator) {
      properties[IMPERSONATED_USER_ID] = claims.impersonator;
    }
  }
  if (ENV === AVAILABLE_ENV.QA && process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.log(
      `%cSimon Tracking Event: ${eventName}`,
      'color: yellow; background: black; font-size: 15px',
      properties
    );
  }
  return gtmPushOldSimon(eventName, properties);
};

export const identify = ({
  userProfile: profile,
  maskedIds = {},
  impersonator,
}) => {
  let maskedUserId = maskedIds.userId;
  const maskedNetworkId = maskedIds.networkId;

  try {
    if (isEmpty(maskedUserId)) {
      captureException("User doesn't have maskedIds.userIds", {
        type: SCOPES.MIXPANEL,
      });
      // Catch-all user id so that Mixpanel ID Merge has a stable key to work with.
      maskedUserId = 'missing';
    }

    if (isEmpty(maskedNetworkId) && ENV === AVAILABLE_ENV.PROD) {
      captureException(
        `Network ${profile.network.id} doesn't have maskedId.networkId`,
        {
          type: SCOPES.MIXPANEL,
        }
      );
    }

    gtmInitialize();
    gtmPush({
      user_id: profile.id,
      impersonated: !!impersonator,
      impersonator_user_id: impersonator || null,
      white_label_partner_guid: profile.network.id,
      maskedUserId,
      maskedNetworkId,
    });

    gtmSetOldSimon('simon_identify', { maskedUserId });

    gtmSetOldSimon('simon_register', {
      'Network ID': maskedNetworkId,
      'User ID': maskedUserId,
    });
    userIdentified = true;
    return trackEvent('Log in', { Action: 'Log In' });
  } catch (e) {
    captureException(`Mixpanel: mixpanelIdentify threw: ${e.message}`);
  }
};

const normalizePath = (path, splitBy = '/') =>
  ((typeof path === 'string' && path) || '').split(splitBy);

export const getPrimaryTabFromPath = path => {
  const splitPath = normalizePath(path);
  return splitPath[splitPath.length - 1];
};

export const getPrimaryTabFromPathWithSubTabsFromPath = path => {
  const splitPath = normalizePath(path);
  return splitPath[splitPath.length - 2];
};

export const getCurrentTabFromUrl = (path, knownTabs = {}) => {
  const tabNameInURL = getPrimaryTabFromPath(path);
  const currentTab = knownTabs[tabNameInURL];
  if (!currentTab) {
    //  Please update in mixpanel excel sheet as well
    console.warn('Unrecognized tab for event tracking', tabNameInURL);
  }
  return currentTab || tabNameInURL;
};

export const checkTabCustom = (tabNameInURL, knownTabs = {}) => {
  const currentTab = knownTabs[tabNameInURL];
  if (!currentTab) {
    return 'Custom';
  }
  return currentTab || tabNameInURL;
};
export const placeBrackets = s => `[${s}]`;

export const TAB_ANNUITIES_HASHTABLE = {
  all: 'Current Contracts',
  pending: 'Pending Contracts',
};

export const useTrackOnce = eventToBeTracked => {
  const [hasTracked, setHasTracked] = useState(false);
  const trackingFunc = useCallback(
    eventProperties => {
      !hasTracked && eventToBeTracked(eventProperties);
      setHasTracked(true);
    },
    [eventToBeTracked, hasTracked]
  );
  return trackingFunc;
};
