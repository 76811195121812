/*
    index file is executed only when the app is accessed directly (without the shell).
    In production environments we always have the shell on top (simonmarkets-shell).
    
    Usually in local development and FS you're accessing the app directly.
    To support those cases, the code here should also contain the initialisation code from the shell.
 */
import { createRoot } from 'react-dom/client';
import AppContainer from './AppContainer';

(async () => {
  // Mock Service Worker
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.REACT_APP_MSW === 'true' &&
    // IE
    !(window.document as any).documentMode
  ) {
    await import('./mocks/browser').then(module =>
      module.worker.start({
        onUnhandledRequest: 'bypass',
      })
    );
  }
})().finally(() => {
  const root = createRoot(document.querySelector('#app'));
  root.render(<AppContainer />);
});

// eslint-disable-next-line no-console
console.log(
  [
    ' ___________',
    '|           |',
    '|   █   █   |',
    '|           |',
    '|   \\___/   |',
    '|___________|',
    '      |',
    '%cStop',
    '%cThis is a browser feature intended for developers. If someone asked you to copy-paste something here, it may be a scam and could give others access to your account.',
  ].join('\n'),
  'font-size: 28px; font-weigth: bold; color: red;',
  'color; black; font-size: 16px'
);
